import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Page from "../components/page"
import {graphql} from 'gatsby'

const NotFoundPage = ({data}) => (
  <Layout>
    <SEO title="404: Not found" />
    <Page widgets={data.datoCmsWebsiteLayout.page404.widgets} />
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query NotFoundQuery {
    datoCmsWebsiteLayout {
      page404 {
        ...Widgets
      }
    }
  }
`
